import React, { useEffect, useRef, useState } from 'react';
import './ChatbotWidget.css';
import { Grid } from '@mui/material';
import { useQueryHandler, useMutationHandler } from './services/useQueryClient';
import {
  CHATBOT_SETTINGS_KEY,
  CONVERSATION_HISTORY_KEY,
  SEND_CONVERSATION_KEY,
} from './services/queryKeys';
import {
  GET_CHATBOT_DETAILS,
  GET_CONVERSATION_HISTORY,
  SEND_CONVERSATION,
} from './services/apiUrls';
import ChatbotLead from './components/ChatbotLead';
import ChatbotInput from './components/ChatbotInput';
import ChatbotFaqs from './components/ChatbotFaqs';
import ChatbotHeader from './components/ChatbotHeader';
import ChatbotPopUpButton from './components/ChatbotPopUpButton';
import ChatbotFooter from './components/ChatbotFooter';
import ChatbotMessages from './components/ChatbotMessages';
import ChatbotMessageEffects from './components/ChatbotMessageEffects';
import ChatbotWelcomeMessage from './components/ChatbotWelcomeMessage';
import LimitExceedErrorMessage from './components/LimitExceedErrorMessage';
import { STATUS_403 } from './utils/constants';
import { metaTagChecker } from './utils/metaTagChecker';

const ChatbotWidget = ({ chatbotId }) => {
  const messageContainerRef = useRef(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [messages, setMessages] = useState({});
  const [faqQuestionId, setFaqQuestionId] = useState('');
  const [leadClose, setLeadClose] = useState(false);

  const { data: chatbotData, isFetched } = useQueryHandler(
    CHATBOT_SETTINGS_KEY,
    `${GET_CHATBOT_DETAILS}${chatbotId}/`
  );

  const params = { uuid: chatbotId };

  const { data: conversationHistory } = useQueryHandler(
    CONVERSATION_HISTORY_KEY,
    GET_CONVERSATION_HISTORY,
    params
  );

  const onMessageSendSuccess = conversation => {
    setMessageInput('');
    setMessages(conversation);
  };

  const onMessageSendError = () => {
    setMessageInput('');
  };

  const { mutate, isLoading, isSuccess, error } = useMutationHandler(
    SEND_CONVERSATION_KEY,
    SEND_CONVERSATION,
    onMessageSendSuccess,
    onMessageSendError
  );

  const sendMessage = query => {
    const payload = { query: query };
    mutate({ payload, params });
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      const container = messageContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    setMessages(conversationHistory);
  }, [conversationHistory]);

  useEffect(() => {
    scrollToBottom();
  }, [messageInput]);

  useEffect(() => {
    metaTagChecker();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 480 && chatOpen) {
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [chatOpen])

  return (
    <>
      {isFetched && (
        <Grid
          className={chatOpen ? 'chat-bot active' : 'chat-bot'}
          style={{
            left: `${chatbotData?.setting?.position === 'left' ? '20px' : ''}`,
          }}
        >
          <>
            <Grid
              className={`bot-popup`}
              style={{
                background: 'transparent',
                position: 'relative',
              }}
            >
              <ChatbotPopUpButton
                setChatOpen={setChatOpen}
                chatOpen={chatOpen}
                color={chatbotData?.setting?.color}
                textColor={chatbotData?.setting?.textColor}
                chatIcon={chatbotData?.setting?.chatIcon}
              />
              {chatOpen && (
                <Grid
                  className={
                    chatbotData?.setting?.position === 'left'
                      ? 'bot-chat animation position-left'
                      : `bot-chat animation`
                  }
                  style={{
                    left: `${chatbotData?.setting?.position === 'left' ? '0' : ''
                      }`,
                  }}
                >
                  <ChatbotHeader
                    chatbotSetting={chatbotData?.setting}
                    setChatOpen={setChatOpen}
                  />
                  <Grid className="bot-body">
                    <Grid
                      className="msgs-scroller"
                      style={{ overflow: 'auto' }}
                      ref={messageContainerRef}
                    >
                      <ChatbotLead
                        chatbotId={chatbotId}
                        chatbotSetting={chatbotData?.setting}
                        isSendMessageSuccess={isSuccess && chatbotData?.setting?.faqs ? chatbotData?.setting?.faqs?.find(item => item?.id === faqQuestionId)?.showLeadForm : false}
                        setLeadClose={setLeadClose}
                        leadClose={leadClose}
                      />
                      <ChatbotMessageEffects
                        isLoading={isLoading}
                        chatbotSetting={chatbotData?.setting}
                        messageInput={messageInput}
                      />
                      <ChatbotMessages
                        messages={messages}
                        chatbotSetting={chatbotData?.setting}
                      />
                      <ChatbotWelcomeMessage
                        messages={messages}
                        chatbotSetting={chatbotData?.setting}
                      />
                    </Grid>
                    {error?.response?.status === parseInt(STATUS_403) ? (
                      <LimitExceedErrorMessage />
                    ) : (
                      <>
                        {chatbotData?.setting?.faqs &&
                          <ChatbotFaqs
                            sendMessage={sendMessage}
                            setMessageInput={setMessageInput}
                            faqs={chatbotData?.setting?.faqs}
                            setFaqQuestionId={setFaqQuestionId}
                            setLeadClose={setLeadClose}
                          />}
                        <ChatbotInput
                          sendMessage={sendMessage}
                          color={chatbotData?.setting?.color}
                          setMessageInput={setMessageInput}
                          setLeadClose={setLeadClose}
                          isLoading={isLoading}
                        />
                        <ChatbotFooter color={chatbotData?.setting?.color} />
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        </Grid>
      )}
    </>
  );
};

export default ChatbotWidget;
