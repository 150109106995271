import { Grid } from '@mui/material';
import { Formik } from 'formik';
import SendIcon from '../assets/svgs/SendIcon';
import * as Yup from 'yup';
import { ENTER_KEY } from '../utils/constants';

const ChatbotInput = ({ sendMessage, color, setMessageInput, setLeadClose, isLoading }) => {
  const inputValidationSchema = Yup.object({
    inputField: Yup.string('Please type something'),
  });

  const sendInputMessage = (values, setFieldError, setFieldValue) => {
    if (!!values.inputField) {
      setMessageInput(values?.inputField);
      sendMessage(values?.inputField);
      setFieldValue('inputField', '');
    } else {
      setFieldError('inputField', 'Please type something');
    }
  };

  return (
    <Formik
      initialValues={{
        inputField: '',
      }}
      validationSchema={inputValidationSchema}
    >
      {({ handleChange, values, errors, setFieldValue, setFieldError }) => {
        return (
          <>
            <Grid className="write-msg">
              <input
                id="inputField"
                type="search"
                placeholder="Type Message"
                value={values.inputField}
                onChange={handleChange}
                onKeyDown={isLoading ? () => { } : e => {
                  if (e.key === ENTER_KEY) {
                    sendInputMessage(values, setFieldError, setFieldValue);
                    setLeadClose(false);
                  }
                }}
              />
              <Grid className="vertical-line" />
              <SendIcon
                color={color}
                onChange={handleChange}
                onClick={isLoading ? () => { } : () => {
                  sendInputMessage(values, setFieldError, setFieldValue);
                  setLeadClose(false);
                }}
              />
            </Grid>
            <Grid>
              {errors?.inputField && (
                <div
                  style={{
                    marginLeft: '18px',
                    marginBottom: '10px',
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  {errors?.inputField}
                </div>
              )}
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default ChatbotInput;
