import { Box, TextField } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const customTheme = outerTheme =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#E0E3E7',
            '--TextField-brandBorderHoverColor': '#B2BAC2',
            '--TextField-brandBorderFocusedColor': '#6F7E8C',
            '& label.Mui-focused': {
              color: '#5532FA',
							lineHeight: '24px',
							top: '0px !important'
            },
						'& label.MuiInputLabel-shrink': {
              top: '4px'
            },
            '& label': {
              color: '#828282',
							fontSize: '12px',
							lineHeight: '12px',
							top: '-4px'
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              border: '1px solid #5532FA',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              border: '1px solid #5532FA',
            },
            '& input': {
              padding:'4px 8px 5px',
							fontSize: '12px',
							height: '25px',
            },
						'& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
							top: '4',
							height: '37px',
						},
          },
        },
      },
    },
  });

export default function CustomInput({
  label,
  placeholder,
  style,
  onChange,
  id,
  value,
}) {
  const outerTheme = useTheme();
  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
      }}
    >
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          id={id}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          style={style}
          onChange={onChange}
          value={value}
        />
      </ThemeProvider>
    </Box>
  );
}
