export const metaTagChecker = () => {
	const metaTag = document.querySelector('meta[name="viewport"]');

	if (metaTag) {
		const content = metaTag.getAttribute('content');

		// Check if "maximum-scale=1" is already present in the content attribute
		if (content && !content.includes('maximum-scale=1')) {
			// Append "maximum-scale=1" to the content attribute
			metaTag.setAttribute('content', `${content}, maximum-scale=1`);
		}
	}
};
