import React from 'react';
import ReactDOM from 'react-dom/client';
import ChatbotWidget from './ChatbotWidget.jsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { CacheBuster } from 'react-cache-buster/dist/CacheBuster.js';
import { version } from '../package.json';
import { STAGE, PROD } from './utils/constants.js';
import * as Sentry from '@sentry/react';

const chatbotWidgetDiv = document.getElementById('chatbot-widget');
const queryClient = new QueryClient();

const isCacheBusterEnable = [STAGE, PROD].includes(process.env.ENV);

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: `${process.env.SENTRY_DSN}`,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['*'],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE_VALUE,
    replaysSessionSampleRate:
      process.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE_VALUE,
    replaysOnErrorSampleRate: process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  });
}
ReactDOM.createRoot(chatbotWidgetDiv).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <CacheBuster
        currentVersion={version}
        isEnabled={isCacheBusterEnable}
        isVerboseMode={false}
        metaFileDirectory={process.env.CHATBOT_WIDGET_BASE_URL}
      >
        <ChatbotWidget chatbotId={chatbotWidgetDiv.dataset.chatbotId} />
      </CacheBuster>
    </QueryClientProvider>
  </React.StrictMode>
);
