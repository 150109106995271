import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import CloseBtn from '../assets/svgs/CloseBtn';
import SendSvg from '../assets/svgs/SendSvg';
import { Grid, Typography } from '@mui/material';
import CustomInput from '../components/CustomInput';
import * as Yup from 'yup';
import { useMutationHandler } from '../services/useQueryClient';
import { CREATE_LEAD_KEY } from '../services/queryKeys';
import { CREATE_LEAD } from '../services/apiUrls';
import { toast } from 'react-hot-toast';

const ChatbotLead = ({ chatbotSetting, chatbotId, isSendMessageSuccess, setLeadClose, leadClose }) => {
  const [error, setError] = useState('');

  const onCreateLeadSuccess = () => {
    toast.success('Thank you for your submission!');
    setLeadClose(false);
  };

  const onCreateLeadError = () => {
    toast.error('Invalid information!');
  };

  const { mutate, isLoading } = useMutationHandler(
    CREATE_LEAD_KEY,
    CREATE_LEAD,
    onCreateLeadSuccess,
    onCreateLeadError
  );

  const handleLeadSubmit = async values => {
    if (!!values.email || !!values.phoneNumber) {
      const payload = {
        email: values.email,
        phoneNumber: values.phoneNumber,
        chatbotUuid: chatbotId,
      };
      setError('');
      mutate({ payload });
    } else {
      setError('Please fill at least one field before proceeding.');
    }
  };

  const leadValidationSchema = Yup.object({
    email: Yup.string().email().optional('Please type email address'),
    phoneNumber: Yup.string().optional('Please type phone number'),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        phoneNumber: '',
      }}
      validationSchema={leadValidationSchema}
    >
      {({ handleChange, errors, values, resetForm }) => {

        useEffect(() => {
          if (!leadClose) {
            resetForm();
          }
        }, [leadClose, resetForm]);

        return (
          <>
            {leadClose && isSendMessageSuccess && (
              <Grid
                sx={{
                  marginTop: '22px',
                  backgroundColor: '#F7F7F7',
                  width: '100%',
                  padding: '16px 17px 17px 22px',
                }}
              >
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography sx={{ color: '#000000', fontSize: '14px', fontWeight: 600, lineHeight: '17px' }}>
                    Let us know how to contact you
                  </Typography>
                  <Grid onClick={() => setLeadClose(false)} sx={{ display: 'flex', height: '10px' }}>
                    <CloseBtn />
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    marginTop: '19px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  <CustomInput
                    onChange={handleChange}
                    id="email"
                    label="Email"
                    value={values.email}
                    placeholder={'Email'}
                    style={{
                      width: '100%',
                    }}
                  />
                  {errors.email && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                      }}
                    >
                      {errors.email}
                    </div>
                  )}
                  <CustomInput
                    onChange={handleChange}
                    id="phoneNumber"
                    label="Phone Number"
                    value={values.phoneNumber}
                    placeholder={'+358 123 456 789'}
                    style={{
                      width: '100%',
                      marginTop: '4px'
                    }}
                  />
                  {errors?.phoneNumber ||
                    (error && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}
                      >
                        {errors?.phoneNumber ? errors?.phoneNumber : error}
                      </div>
                    ))}

                  <Grid
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {isLoading ? (
                      <p>
                        <span className="loader"></span>
                      </p>
                    ) : (
                      <SendSvg
                        color={chatbotSetting?.color}
                        onClick={() => handleLeadSubmit(values)}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default ChatbotLead;
