import { useMutation, useQuery } from '@tanstack/react-query';
import { getRequest, postRequest } from './apiClient';

export const useQueryHandler = (key, url, params) => {
  return useQuery([key], () => getRequest(url, params));
};

export const useMutationHandler = (key, url, onSuccess, onError) => {
  return useMutation({
    mutationKey: [key],
    mutationFn: ({ payload, params }) => postRequest(url, payload, params),
    onSuccess: onSuccess,
    onError: onError,
  });
};
