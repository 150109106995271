import { axiosClient } from './axiosSetup';

export const getRequest = async (url, params) => {
  const response = await axiosClient.get(url, { params });
  return response.data;
};

export const postRequest = async (url, payload, params) => {
  const response = await axiosClient.post(url, payload, { params });
  return response.data;
};
