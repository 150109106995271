import React from 'react';

const ChatPopUpIcon = ({ color }) => {
	return (
		<svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<circle cx='30' cy='30' r='30' fill={color} />
		</svg>
	);
};

export default ChatPopUpIcon;
