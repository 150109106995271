import { Grid } from '@mui/material';
import ChatPopUpIcon from '../assets/svgs/ChatPopUpIcon';
import RedNotificationIcon from '../assets/svgs/RedNotificationIcon';
import BlackNotificationIcon from '../assets/svgs/BlackNotificationIcon';
import { RED_COLOR } from '../utils/constants';

const ChatbotPopUpButton = ({ color, setChatOpen, chatOpen, textColor, chatIcon }) => {
  return (
    <Grid
      onClick={() => setChatOpen(!chatOpen)}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
			className='chatbot-popup-button'
    >
      <Grid
        sx={{
          position: 'relative',
        }}
      >
        <Grid sx={{ position: 'relative' }}>
          <ChatPopUpIcon color={color} textColor={textColor} />
          {!!chatIcon ? (
            <Grid
              sx={{
                position: 'absolute',
                borderRadius: '99px',
                maxHeight: '40px',
                maxWidth: '40px',
                height: '40px',
                width: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left: '0',
                right: '0',
                top: '10px',
                marginLeft: 'auto',
                marginRight: 'auto',
                objectFit: 'cover',
                overflow: 'hidden',
              }}
            >
              <img src={chatIcon?.image} alt="Icon Image" />
            </Grid>
          ) : (
            <Grid
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                left: '0',
                right: '0',
                top: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '18px',
                lineHeight: '22px',
                fontWeight: '700',
              }}
            >
              chat
            </Grid>
          )}
        </Grid>
        <Grid sx={{ position: 'absolute', top: '-8px', right: '-2px' }}>
					{color === RED_COLOR ? <BlackNotificationIcon /> : <RedNotificationIcon />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatbotPopUpButton;
