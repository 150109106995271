import { Grid } from '@mui/material';

const ChatbotFaqs = ({ faqs, setMessageInput, sendMessage, setFaqQuestionId, setLeadClose }) => {
  return (
    <>
      {faqs.length !== 0 && (
        <Grid
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '6px',
            margin: '12px 20px',
            '.faq-question': {
              backgroundColor: 'rgba(225, 225, 225, 0.27)',
              padding: '6px',
              borderRadius: '3px',
              fontSize: '14px',
              color: '#000000',
            },
          }}
        >
          {faqs?.map(faq => (
            <Grid
              key={faq?.id}
              className="faq-question"
              onClick={() => {
                setMessageInput(faq?.question);
                sendMessage(faq?.question);
                setFaqQuestionId(faq?.id);
                setLeadClose(true);
              }}
            >
              {faq?.question}
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ChatbotFaqs;
