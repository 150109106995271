import axios from 'axios';
import { toast } from 'react-hot-toast';
import * as convertCase from 'change-object-case';

export const axiosClient = axios.create();

axiosClient.defaults.baseURL = `${process.env.BACKEND_BASE_URL}/`;
axiosClient.defaults.withCredentials = true;

const options = { recursive: true, arrayRecursive: true };

axiosClient.interceptors.request.use(
  request => {
    if (request?.data) {
      const caseConvertedData = convertCase.snakeKeys(request.data, options);
      request.data = caseConvertedData;
    }
    return request;
  },
  null,
  { synchronous: true }
);

axiosClient.interceptors.response.use(
  response => {
    if (response?.data) {
      const caseConvertedData = convertCase.camelKeys(response.data, options);
      response.data = caseConvertedData;
    }
    return response;
  },
  error => {
    if (error.response.status >= 500 && error.response.status < 600) {
      toast.error('Something went wrong, please try again laster!');
    }
    return Promise.reject(error);
  }
);
