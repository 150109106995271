import React from 'react'

const ChatbotIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.57808 13.3841H7.29C7.08912 13.3841 6.92712 13.2213 6.92712 13.0212V6.72839C6.92712 6.52751 7.08984 6.36551 7.29 6.36551H8.57808C8.77896 6.36551 8.94096 6.52823 8.94096 6.72839V13.0212C8.94168 13.2213 8.77896 13.3841 8.57808 13.3841Z" fill="#00347B" />
            <path d="M28.6287 13.3841H27.3407C27.1398 13.3841 26.9778 13.2213 26.9778 13.0212V6.72839C26.9778 6.52751 27.1405 6.36551 27.3407 6.36551H28.6287C28.8296 6.36551 28.9916 6.52823 28.9916 6.72839V13.0212C28.9916 13.2213 28.8289 13.3841 28.6287 13.3841Z" fill="#00347B" />
            <path d="M10.6056 21.4106H3.8902C3.37684 21.4106 2.95996 20.9945 2.95996 20.4804V13.338C2.95996 12.8246 3.37612 12.4077 3.8902 12.4077H10.6056C11.119 12.4077 11.5359 12.8239 11.5359 13.338V20.4804C11.5352 20.9937 11.119 21.4106 10.6056 21.4106Z" fill="#4793FF" />
            <path d="M32.9724 19.2355C32.9775 17.951 30.9809 17.9489 30.9759 19.2355C30.9716 20.412 30.9672 21.5885 30.9622 22.7657C30.9579 23.8817 31.0126 24.9451 30.3538 25.9027C29.7929 26.7185 29.2947 27.1591 28.2932 27.2894C27.0656 27.4485 25.7796 27.5407 24.5441 27.4766C23.2604 27.4104 23.264 29.4069 24.5441 29.4732C26.0021 29.5488 27.5775 29.4501 29.0175 29.1895C30.2472 28.967 31.0011 28.3197 31.7419 27.3585C33.493 25.0869 32.9616 21.9369 32.9724 19.2355Z" fill="#00429D" />
            <path d="M32.0595 21.4106H25.3441C24.8307 21.4106 24.4138 20.9945 24.4138 20.4804V13.338C24.4138 12.8246 24.83 12.4077 25.3441 12.4077H32.0595C32.5729 12.4077 32.9897 12.8239 32.9897 13.338V20.4804C32.9897 20.9937 32.5729 21.4106 32.0595 21.4106Z" fill="#525CDD" />
            <path d="M18.0036 4.98383C12.9629 4.98383 8.65371 7.96535 6.89403 12.1824C6.64131 12.7879 6.52539 13.4424 6.52539 14.0983V18.7207C6.52539 19.4285 6.67083 20.1283 6.95163 20.7777C6.97755 20.8375 7.00347 20.8965 7.03011 20.9556C7.85379 22.77 9.69699 23.9047 11.69 23.9047H24.3173C26.3103 23.9047 28.1528 22.77 28.9772 20.9556C29.0038 20.8965 29.0304 20.8375 29.0556 20.7777C29.3372 20.1283 29.4819 19.4285 29.4819 18.7207V14.0983C29.4819 13.4424 29.366 12.7879 29.1132 12.1824C27.3543 7.96535 23.0444 4.98383 18.0036 4.98383Z" fill="#F0F5FA" />
            <path d="M21.6138 31.0154C23.4974 31.0154 25.0244 29.8298 25.0244 28.3673C25.0244 26.9047 23.4974 25.7191 21.6138 25.7191C19.7301 25.7191 18.2031 26.9047 18.2031 28.3673C18.2031 29.8298 19.7301 31.0154 21.6138 31.0154Z" fill="#00347B" />
            <path d="M29.3135 12.1831C27.5538 7.96536 23.2446 4.98456 18.2039 4.98456V23.9054H24.5175C26.5105 23.9054 28.353 22.7707 29.1774 20.9563C29.204 20.8973 29.2307 20.8382 29.2559 20.7785C29.5374 20.129 29.6821 19.4292 29.6821 18.7214V14.099C29.6821 13.4424 29.5662 12.7886 29.3135 12.1831Z" fill="#D7E3F0" />
            <path d="M25.1294 21.4106H11.2773C10.2146 21.4106 9.34558 20.5416 9.34558 19.4789V12.6302C9.34558 11.5675 10.2146 10.6985 11.2773 10.6985H25.1294C26.1921 10.6985 27.0612 11.5675 27.0612 12.6302V19.4789C27.0612 20.5409 26.1921 21.4106 25.1294 21.4106Z" fill="#D7E3F0" />
            <path d="M21.7051 10.6985H18.203V21.4099H21.7051C24.6506 21.4099 27.0612 19.0001 27.0612 16.0538C27.0612 13.109 24.6513 10.6985 21.7051 10.6985Z" fill="#A0B6DB" />
            <path d="M24.1323 19.6711H12.2754C11.5582 19.6711 10.9714 19.0843 10.9714 18.3672V13.7433C10.9714 13.0262 11.5582 12.4394 12.2754 12.4394H24.1323C24.8494 12.4394 25.4362 13.0262 25.4362 13.7433V18.3672C25.4362 19.0836 24.8494 19.6711 24.1323 19.6711Z" fill="#00429D" />
            <path d="M21.8197 12.438H18.2031V19.6704H21.8197C23.8083 19.6704 25.4362 18.0432 25.4362 16.0538C25.4362 14.0652 23.809 12.438 21.8197 12.438Z" fill="#00347B" />
            <path d="M15.0221 17.573C15.5995 17.573 16.0676 16.9444 16.0676 16.169C16.0676 15.3936 15.5995 14.765 15.0221 14.765C14.4447 14.765 13.9767 15.3936 13.9767 16.169C13.9767 16.9444 14.4447 17.573 15.0221 17.573Z" fill="#4793FF" />
            <path d="M21.8578 17.4967C22.4352 17.4967 22.9033 16.8681 22.9033 16.0927C22.9033 15.3173 22.4352 14.6887 21.8578 14.6887C21.2804 14.6887 20.8124 15.3173 20.8124 16.0927C20.8124 16.8681 21.2804 17.4967 21.8578 17.4967Z" fill="#525CDD" />
        </svg>

    )
}

export default ChatbotIcon
