const SendSvg = ({ onClick, color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <rect width="32" height="32" rx="4" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5682 15.0135L20.4239 18.4464C19.6171 20.8668 19.2137 22.0771 18.6222 22.421C18.0594 22.7481 17.3644 22.7481 16.8016 22.421C16.21 22.0771 15.8066 20.8669 14.9998 18.4464C14.8703 18.0578 14.8055 17.8635 14.6968 17.7011C14.5914 17.5437 14.4562 17.4084 14.2988 17.3031C14.1364 17.1943 13.942 17.1296 13.5534 17C11.133 16.1932 9.92278 15.7898 9.57887 15.1982C9.25171 14.6355 9.25171 13.9404 9.57887 13.3777C9.92278 12.7861 11.133 12.3827 13.5534 11.5759L16.9863 10.4316C19.9852 9.43195 21.4847 8.93213 22.2762 9.72364C23.0677 10.5152 22.5679 12.0146 21.5682 15.0135ZM16.6973 15.2678C16.5031 15.0715 16.5049 14.7549 16.7012 14.5607L19.5082 11.7846C19.7046 11.5904 20.0211 11.5922 20.2153 11.7885C20.4095 11.9849 20.4077 12.3015 20.2114 12.4956L17.4044 15.2717C17.208 15.4659 16.8915 15.4641 16.6973 15.2678Z"
        fill="white"
      />
    </svg>
  );
};
export default SendSvg;
