const CloseBtn = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0228 0H1.87622C0.833875 0 0 0.833875 0 1.87622V11.2573C0 12.2997 0.833875 13.1335 1.87622 13.1335H35.0228C36.0651 13.1335 36.899 12.2997 36.899 11.2573V1.87622C36.899 0.833875 36.013 0 35.0228 0ZM7.24429 10.4756C5.10749 10.4756 3.3355 8.70357 3.3355 6.56677C3.3355 4.42996 5.10749 2.65798 7.24429 2.65798C9.3811 2.65798 11.1531 4.42996 11.1531 6.56677C11.1531 8.70357 9.3811 10.4756 7.24429 10.4756ZM18.4495 10.4756C16.3127 10.4756 14.5407 8.70357 14.5407 6.56677C14.5407 4.42996 16.3127 2.65798 18.4495 2.65798C20.5863 2.65798 22.3583 4.42996 22.3583 6.56677C22.3583 8.75569 20.5863 10.4756 18.4495 10.4756ZM29.4983 10.4756C27.3615 10.4756 25.5896 8.70357 25.5896 6.56677C25.5896 4.42996 27.3615 2.65798 29.4983 2.65798C31.6351 2.65798 33.4071 4.42996 33.4071 6.56677C33.4071 8.75569 31.6351 10.4756 29.4983 10.4756Z"
        fill="white"
      />
      <path
        d="M8.99997 1.00003L1 9M0.999966 1L8.99993 8.99997"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default CloseBtn;
