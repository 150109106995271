import { Avatar, Grid } from '@mui/material';
import CloseIcon from '../assets/svgs/CloseIcon';
import ChatbotIcon from '../assets/svgs/ChatbotIcon.jsx';

const ChatbotHeader = ({ setChatOpen, chatbotSetting }) => {
  return (
    <Grid className="bot-header" style={{ background: chatbotSetting?.color }}>
      <Grid className="chat-box-header">
        {chatbotSetting?.chatbotImage?.image ? (
          <Avatar
            src={chatbotSetting?.chatbotImage?.image}
            sx={{ width: 40, height: 40 }}
          />
        ) : (
          <ChatbotIcon />
        )}
        <Grid className="chat-box-heading-wrapper">
          <Grid
            style={{ color: chatbotSetting?.textColor }}
            className="chat-box-heading"
          >
            {chatbotSetting?.botName}
          </Grid>
          <Grid
            style={{ color: chatbotSetting?.textColor }}
            className="chat-box-subHeading"
          >
            Online Now
          </Grid>
        </Grid>
      </Grid>
      <Grid onClick={() => setChatOpen(false)}>
        <CloseIcon color={chatbotSetting?.textColor} />
      </Grid>
    </Grid>
  );
};

export default ChatbotHeader;
