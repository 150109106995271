import { Avatar, Grid } from '@mui/material';
import { formatDatetime } from '../utils';
import ChatbotIcon from '../assets/svgs/ChatbotIcon.jsx';

const ChatbotMessageEffects = ({ isLoading, chatbotSetting, messageInput }) => {
  return (
    <>
      {isLoading && (
        <Grid className="message-receive-wrapper">
          <Grid className="msg-receive">
            <Grid className="message-wrapper">
              <Grid className="message-wrapper">
                <>
                  {chatbotSetting?.chatbotImage?.image ? (
                    <Avatar
                      src={chatbotSetting?.chatbotImage?.image}
                      sx={{ width: 32, height: 32 }}
                    />
                  ) : (
                    <ChatbotIcon />
                  )}
                  <span>
                    <p className="loader"></p>
                  </span>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {isLoading && (
        <Grid className="message-sent-wrapper">
          <Grid className="msg-sent">
            <p
              style={{
                background: chatbotSetting?.color,
                color: chatbotSetting?.textColor,
              }}
            >
              {messageInput}
            </p>
            <Grid className="label-wrapper">
              <label className="display-time">
                {formatDatetime(new Date())}
              </label>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ChatbotMessageEffects;
