import { Grid } from '@mui/material';

const LimitExceedErrorMessage = () => {
  return (
    <Grid className="exceed-error-message">
      You have reached your messages limit and you can’t message more. Lets{' '}
      <a
        style={{
          textDecoration: 'none',
          color: '#5532FA',
          fontWeight: 600,
        }}
        href={`${process.env.BACKEND_BASE_URL}`}
      >
        Upgrade{' '}
      </a>
      your plan to chat more.
    </Grid>
  );
};

export default LimitExceedErrorMessage;
