{
  "name": "chatbot-widget",
  "homepage": ".",
  "private": true,
  "version": "0.0.13",
  "type": "module",
  "scripts": {
    "dev": "parcel serve -p 3000 index.html",
    "generate-meta-tag": "node ./node_modules/react-cache-buster/dist/generate-meta-tag.js",
    "build": "yarn generate-meta-tag && parcel build src/main.jsx && cp ./public/meta.json ./dist",
    "preview": "serve dist",
    "lint": "eslint . --ext js,jsx --report-unused-disable-directives --max-warnings 0"
  },
  "dependencies": {
    "@emotion/react": "^11.11.1",
    "@emotion/styled": "^11.11.0",
    "@mui/material": "^5.14.5",
    "@mui/styled-engine-sc": "^5.12.0",
    "@mui/x-data-grid": "^6.11.2",
    "@sentry/react": "^7.65.0",
    "@tanstack/react-query": "^4.32.6",
    "axios": "^1.4.0",
    "change-object-case": "^0.2.1",
    "dotenv": "^16.3.1",
    "formik": "^2.4.3",
    "moment": "^2.29.4",
    "react": "^18.2.0",
    "react-cache-buster": "^0.1.8",
    "react-dom": "^18.2.0",
    "react-hot-toast": "^2.4.1",
    "styled-components": "^6.0.7",
    "yup": "^1.2.0"
  },
  "devDependencies": {
    "@types/react": "^18.2.15",
    "@types/react-dom": "^18.2.7",
    "buffer": "^5.5.0||^6.0.0",
    "eslint": "^8.45.0",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-react-refresh": "^0.4.3",
    "parcel": "^2.11.0",
    "posthtml-expressions": "^1.11.1",
    "process": "^0.11.10",
    "serve": "^14.2.0"
  }
}
