import { Avatar, Grid } from '@mui/material';
import ChatbotIcon from '../assets/svgs/ChatbotIcon';

const ChatbotWelcomeMessage = ({ messages, chatbotSetting }) => {
  return (
    <>
      {messages?.results && (
        <Grid className="message-receive-wrapper">
          <Grid className="msg-receive">
            <Grid className="message-wrapper">
              {chatbotSetting?.chatbotImage?.image ? (
                <Avatar
                  src={chatbotSetting?.chatbotImage?.image}
                  sx={{ width: 32, height: 32 }}
                />
              ) : (
                <ChatbotIcon />
              )}
              <span>{chatbotSetting?.welcomeMessage}</span>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ChatbotWelcomeMessage;
