const SendIcon = ({ onClick, color = '#5532FA' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8042 10.8167L19.1289 15.8427C17.9476 19.3864 17.357 21.1583 16.4909 21.6618C15.667 22.1407 14.6494 22.1407 13.8255 21.6618C12.9594 21.1582 12.3688 19.3864 11.1875 15.8427C10.9979 15.2738 10.903 14.9892 10.7439 14.7515C10.5896 14.521 10.3916 14.323 10.1612 14.1687C9.92336 14.0096 9.63886 13.9147 9.06987 13.7251C5.52619 12.5438 3.75435 11.9532 3.25084 11.0871C2.77185 10.2632 2.77185 9.2456 3.25085 8.42168C3.75435 7.55559 5.52619 6.96497 9.06987 5.78375L14.0959 4.10841C18.4865 2.64486 20.6819 1.91309 21.8407 3.07192C22.9995 4.23075 22.2677 6.42608 20.8042 10.8167ZM13.6727 11.189C13.3885 10.9015 13.391 10.438 13.6785 10.1537L17.7882 6.08934C18.0756 5.80505 18.5391 5.80762 18.8234 6.09508C19.1077 6.38254 19.1051 6.84603 18.8177 7.13032L14.708 11.1947C14.4205 11.479 13.957 11.4764 13.6727 11.189Z"
        fill={color}
      />
    </svg>
  );
};

export default SendIcon;
