import { Grid } from '@mui/material';

const ChatbotFooter = ({ color }) => {
  return (
    <Grid className="powered-by">
      Powered By{' '}
      <a
        href={process.env.BACKEND_BASE_URL}
        style={{ color: color, textDecoration: 'none' }}
      >
        Booosted.com
      </a>
    </Grid>
  );
};

export default ChatbotFooter;
