import { Avatar, Grid } from '@mui/material';
import { formatDatetime } from '../utils';
import { Fragment } from 'react';
import ChatbotIcon from '../assets/svgs/ChatbotIcon.jsx';
import { USER } from '../utils/constants';

const ChatbotMessages = ({ chatbotSetting, messages }) => {
  return (
    <>
      {messages?.results
        ?.map(message => {
          return (
            <Fragment key={message?.id}>
              {message?.role === USER && (
                <Grid className="message-sent-wrapper">
                  <Grid className="msg-sent">
                    <p
                      style={{
                        background: chatbotSetting?.color,
                        color: chatbotSetting?.textColor,
                      }}
                    >
                      {message?.text}
                    </p>
                    <Grid className="label-wrapper">
                      <label className="display-time">
                        {formatDatetime(message?.created)}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {message?.role !== USER && (
                <Grid className="message-receive-wrapper">
                  <Grid className="msg-receive">
                    <Grid className="message-wrapper">
                      {chatbotSetting?.chatbotImage?.image ? (
                        <Avatar
                          src={chatbotSetting?.chatbotImage?.image}
                          sx={{ width: 32, height: 32 }}
                        />
                      ) : (
                        <Grid sx={{height:'36px', width:'36px'}}>
                          <ChatbotIcon />
                        </Grid>
                      )}
                      <span dangerouslySetInnerHTML={{ __html: message?.text }} />
                    </Grid>
                    <Grid className="label-wrapper">
                      <label className="display-time">
                        {formatDatetime(message?.created)}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Fragment>
          );
        })
        .reverse()}
    </>
  );
};

export default ChatbotMessages;
